var WORDS = [
  "navy",
  "bible",
  "banyan",
  "heritage",
  "stanton",
  "watermelon",
  "brotherhood",
  "crown",
  "aramis",
  "bonham"
];

var HINT = [
  "Colour of school trousers",
  "A historical book that we use in RS",
  "House with SPC’s blue colour",
  "Related to SPC’s long history/__ service",
  "First Principal of SPC",
  "The __ ball is a traditional sport in SPC",
  "An important element of life in SPC",
  "It refers to the Kingship of Jesus",
  "The __ Bell is located on the 5th floor as decoration",
  "SPC is currently located on the __ Road"
];

function randomWord(rand) {
  return [WORDS[rand], HINT[rand]];
}

export { randomWord };
