import React, { Component } from "react";
import { randomWord } from "./words";

import "./Hangman.css";

import img0 from "./images/0.jpg";
import img1 from "./images/1.jpg";
import img2 from "./images/2.jpg";
import img3 from "./images/3.jpg";
import img4 from "./images/4.jpg";
import img5 from "./images/5.jpg";
import img6 from "./images/6.jpg";

class Hangman extends Component {
  /** by default, allow 6 guesses and use provided gallows images. */
  static defaultProps = {
    maxWrong: 6,
    images: [img0, img1, img2, img3, img4, img5, img6],
  };

  constructor(props) {
    super(props);
    const rUnit = Math.floor(Math.random() * 10);
    this.state = {
      nWrong: 0,
      guessed: new Set(),
      answer: randomWord(rUnit)[0],
      hint: randomWord(rUnit)[1],
    };

    this.handleGuess = this.handleGuess.bind(this);
    this.resetGame = this.resetGame.bind(this);
  }

  // reset the game and put things in default
  resetGame() {
    const rUnit = Math.floor(Math.random() * 10);
    this.setState({
      nWrong: 0,
      guessed: new Set(),
      answer: randomWord(rUnit)[0],
      hint: randomWord(rUnit)[1],
    });
  }

  /** guessedWord: show current-state of word:
    if guessed letters are {a,p,e}, show "app_e" for "apple"
  */
  guessedWord() {
    // deconstructor
    const { answer, guessed } = this.state;

    return answer.split("").map((ltr) => (guessed.has(ltr) ? ltr : "_"));
  }

  /** handleGuest: handle a guessed letter:
    - add to guessed letters
    - if not in answer, increase number-wrong guesses
  */
  handleGuess(evt) {
    let ltr = evt.target.value;

    this.setState((st) => ({
      guessed: st.guessed.add(ltr),
      nWrong: st.nWrong + (st.answer.includes(ltr) ? 0 : 1),
    }));
  }

  /** generateButtons: return array of letter buttons to render */
  generateButtons() {
    const { handleGuess } = this;
    const { guessed } = this.state;

    return "abcdefghijklmnopqrstuvwxyz".split("").map((ltr, index) => (
      <button
        key={index}
        value={ltr}
        onClick={handleGuess}
        disabled={guessed.has(ltr)}
        className="word-btn"
      >
        {ltr}
      </button>
    ));
  }

  /** render: render game */
  render() {
    const { nWrong, answer, hint } = this.state;
    const { images, maxWrong } = this.props;

    let alternateText = `${this.state.nWrong} wrong guesses`;
    // const { guessedWord, generateButtons } = this;

    return (
      <div className="Hangman">
        <div className="row">
          <div className="column">
            <img src={images[nWrong]} alt={alternateText} />
          </div>
          <div className="column">
            <p>Number Wrong: {nWrong} / 6</p>
            <p>Hint: {hint}</p>

            {answer === this.guessedWord().join("") ? (
              <p>You WIN!</p>
            ) : nWrong === maxWrong ? (
              <div>
                <p>YOU LOSE </p>
                <p>Correct Word is: {answer}</p>
              </div>
            ) : (
              <div>
                <p className="Hangman-word">{this.guessedWord()}</p>
                <p className="Hangman-btns">{this.generateButtons()}</p>
              </div>
            )}

            <button id="reset" className="reset-btn" onClick={this.resetGame}>
              Reset Game
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Hangman;
